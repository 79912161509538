import type { UserConsentManagementModel } from "@cm-websites/common";

export const GROUPS: UserConsentManagementModel.Group[] = [
  {
    id: "essential",
    description:
      "Essential cookies and data processing enable basic functions and are necessary for the proper functioning of the website.",
    name: "Essential",
    required: true,
  },
  {
    id: "analytics",
    description:
      "Statistical cookies and data processing collect information anonymously. This information helps us as operators to understand how visitors use our offers.",
    name: "Statistics",
    required: false,
  },
  {
    id: "external-media",
    description:
      "Content from video platforms and social media platforms is blocked by default. If cookies from external media are accepted, access to this content no longer requires manual consent.",
    name: "External media",
    required: false,
  },
];

export const PROCESSES: UserConsentManagementModel.Process[] = [
  {
    group: "essential",
    id: "consent",
    name: "Privacy settings",
    privacyPolicyUrl: "/privacy-policy",
    provider: "Bischoff, Kauffmann & Otani GbR",
    purpose: "Saves the user's privacy settings.",
    required: true,
  },
  {
    group: "essential",
    id: "locale",
    name: "Locale / language",
    privacyPolicyUrl: "/privacy-policy",
    provider: "Bischoff, Kauffmann & Otani GbR",
    purpose: "Saves the user's locale and language and currency.",
    required: true,
  },
  // {
  //   group: "essential",
  //   id: "shop",
  //   name: "Warenkorb",
  //   privacyPolicyUrl: "/datenschutzerklaerung",
  //   provider: "Bischoff, Kauffmann & Otani GbR",
  //   purpose:
  //     "Stellt die Warenkorb Funktionalität zur Verfügung, speichert die Session mit den in den Warenkorb gelegten Waren und dem Checkout-Status",
  //   required: true,
  // },
  {
    group: "analytics",
    id: "google-analytics",
    name: "Google Analytics",
    privacyPolicyUrl: "https://policies.google.com/privacy",
    provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
    purpose:
      "Cookie from Google for website analytics. Generates statistical data about how visitors uses the website.",
    required: false,
  },
  {
    group: "external-media",
    id: "youtube",
    name: "YouTube",
    provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
    purpose: "Used to unlock YouTube content.",
    privacyPolicyUrl: "https://policies.google.com/privacy",
    required: false,
  },
];
