import { createTheme } from "@cm-websites/common";
import { EB_Garamond, Roboto } from "@next/font/google";
import createCache from "@emotion/cache";

// Site-specific theme modification would happen here

export const roboto = Roboto({
  display: "swap",
  fallback: ["Helvetica", "Arial", "sans-serif"],
  style: ["normal"],
  subsets: ["latin"],
  weight: ["300", "400", "500"],
});

export const garamond = EB_Garamond({
  weight: ["400"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--eb-garamond-font",
  display: "swap",
  fallback: [
    "Garamond",
    "Baskerville",
    "Baskerville Old Face",
    "Hoefler Text",
    "Times New Roman",
    "Times",
    "serif",
  ],
});

export const theme = createTheme({
  sansSerifFont: roboto.style.fontFamily,
  serifFont: garamond.style.fontFamily,
});

const isBrowser = typeof document !== "undefined";

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]'
    );
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  return createCache({ key: "mui-style", insertionPoint });
}
