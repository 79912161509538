import path from "path";

const config = {
  i18n: {
    locales: ["en"],
    defaultLocale: "en",
    domains: [
      {
        domain: "www.culinario-mortale.com",
        defaultLocale: "en",
      },
    ],
    defaultNS: "c-common",
    returnNull: false,
  },
  ...(typeof window === undefined ? { localePath: path.resolve("./public/locales") } : {}),
};

export default config;
